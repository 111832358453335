import * as Yup from 'yup'
import { ClearFormatMoney } from '../../../../Utils'
/* eslint-disable import/prefer-default-export */
export const schema = Yup.object().shape({
  keyHistory: Yup.string().required(),
  currentKeyHistory: Yup.string().required(),
  isRequiredDescriptions: Yup.boolean(),
  descriptions: Yup.string().when('isRequiredDescriptions', {
    is: true,
    then: Yup.string().required('Este campo es requerido')
  }),
  isRequiredMoreDescriptions: Yup.boolean(),
  moreDescriptions: Yup.array(),
  isRequiredDate: Yup.boolean(),
  dateDelivery: Yup.string().when('isRequiredDate', {
    is: true,
    then: Yup.string().required('La fecha de entrega es requerida')
  }),
  isRequiredGiftCardActive: Yup.boolean(),
  giftcardActive: Yup.boolean(),
  isRequiredDocuments: Yup.boolean(),
  verificationOfDocuments: Yup.string(),
  documentsNames: Yup.array()
    .when('isRequiredDocuments', {
      is: true,
      then: Yup.array()
        .min(1, 'Se requieren al Menos 1 documento')
        .required('Se requieren al Menos 1 documento')
    })
    .when('verificationOfDocuments', {
      is: 'APPROVED',
      then: Yup.array()
        .min(1, 'Se requieren al Menos 1 documento')
        .required('El documento es requerido')
        .nullable()
    }),
  isRequiredPayment: Yup.boolean(),
  paymentVerification: Yup.string().when('isRequiredPayment', {
    is: true,
    then: Yup.string().required('La verificación de pago es requerida')
  }),
  isRequiredValidationOffice: Yup.boolean(),
  validationOffice: Yup.string().when('isRequiredValidationOffice', {
    is: true,
    then: Yup.string().required('La validación del officio es requerida')
  }),
  isRequiredAuthorization: Yup.boolean(),
  orderAuthorization: Yup.string().when('isRequiredAuthorization', {
    is: true,
    then: Yup.string().required('La autorización de orden es requerida')
  }),
  isReuiredAlterProcess: Yup.boolean(),
  alterProcess: Yup.string().when('isReuiredAlterProcess', {
    is: true,
    then: Yup.string().required('El proceso de alteración es requerido')
  }),
  isRequiredQuestionNewSemesters: Yup.boolean(),
  responseQuestionNewSemesters: Yup.string().when(
    'isRequiredQuestionNewSemesters',
    {
      is: true,
      then: Yup.string().required('La respuesta a la pregunta es requerida')
    }
  ),
  isRequiredProposedState: Yup.boolean(),
  proposedState: Yup.string().when('isRequiredProposedState', {
    is: true,
    then: Yup.string().required('El estado propuesto es requerido')
  }),
  isRequiredAmount: Yup.boolean(),
  amount: Yup.string().when('isRequiredAmount', {
    is: true,
    then: Yup.string().required('El monto es requerido')
  }),
  isRequiredOfficeStatus: Yup.boolean(),
  officeStatus: Yup.string().when('isRequiredOfficeStatus', {
    is: true,
    then: Yup.string().required('validación de estado es requerido')
  }),
  isRequiredVoucher: Yup.boolean(),
  voucherName: Yup.array().when('isRequiredVoucher', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El documento es requerido')
  }),
  isRequiredInstructive: Yup.boolean(),
  instructiveName: Yup.array().when('isRequiredInstructive', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El documento es requerido')
  }),
  isRequiredTransferredAmount: Yup.boolean(),
  transferredAmount: Yup.string().when('isRequiredTransferredAmount', {
    is: true,
    then: Yup.string()
      .required('El monto es requerido')
      .test(
        'valid-for-sc-si-fss',
        'El monto transferido no puede ser mayor al valor estimado de ayuda',
        (value, context) => {
          const { amount, currentKeyHistory } = context.parent

          const isKeyOfffs = Boolean(
            currentKeyHistory === 'USED-AMOUNT-SOLIDARITY-HEALTH-FUND'
          )

          const amountClean = ClearFormatMoney(amount || '0')
          const usedAmountClean = ClearFormatMoney(value || '0')

          let isValid = true

          if (!isKeyOfffs) {
            isValid =
              !amountClean ||
              !usedAmountClean ||
              parseFloat(amountClean) >= parseFloat(usedAmountClean)
          }

          return isValid
        }
      )
  }),
  isRequiredUsedAmount: Yup.boolean(),
  usedAmount: Yup.string().when('isRequiredUsedAmount', {
    is: true,
    then: Yup.string().required('El monto es requerido')
  }),
  isRequiredBackupFeeAmount: Yup.boolean(),
  backupFeeAmountName: Yup.array().when('isRequiredBackupFeeAmount', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El docuemnto es requerido')
  }),
  isRequiredScholarshipCommission: Yup.boolean(),
  scholarshipCommissionName: Yup.array().when(
    'isRequiredScholarshipCommission',
    {
      is: true,
      then: Yup.array()
        .min(1, 'Se requieren al Menos 1 documento')
        .required('El docuemnto es requerido')
    }
  ),
  isRequiredBankData: Yup.boolean(),
  bankDataName: Yup.array(),
  isRequiredOffice: Yup.boolean(),
  officeName: Yup.array().when(['isRequiredOffice', 'validationOffice'], {
    is: (isRequiredOffice, validationOffice) =>
      isRequiredOffice === true && validationOffice !== 'EDIT',
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El docuemnto es requerido')
  }),
  isRequiredInvoice: Yup.boolean(),
  invoiceName: Yup.array().when('isRequiredInvoice', {
    is: true,
    then: Yup.array()
  }),
  isRequiredReportedEmail: Yup.boolean(),
  reportedEmail: Yup.string().when('isRquiredReportedEmail', {
    is: true,
    then: Yup.string().required('El correo es requerido')
  }),
  isRequiredBoxPurchase: Yup.boolean(),
  // boxPurchaseName: Yup.array().when('isRequiredBoxPurchase', {
  //   is: true,
  //   then: Yup.array()
  //     .min(1, 'Se requieren al Menos 1 documento')
  //     .required('El documento es requerido')
  // }),
  isRequiredBoxPurchaseTwo: Yup.boolean(),
  boxPurchaseTwoName: Yup.array().when('isRequiredBoxPurchaseTwo', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El documento es requerido')
  }),
  isRequiredPurchaseRequestLetter: Yup.boolean(),
  purchaseRequestLetterName: Yup.array().when(
    'isRequiredPurchaseRequestLetter',
    {
      is: true,
      then: Yup.array()
        .min(1, 'Se requieren al Menos 1 documento')
        .required('El documento es requerido')
    }
  ),
  isRequiredQuotesReceived: Yup.boolean(),
  quotesReceivedName: Yup.array().when('isRequiredQuotesReceived', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El documento es requerido')
  }),
  isRequiredDebtCertificate: Yup.boolean(),
  debtCertificateName: Yup.array().when('isRequiredDebtCertificate', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El documento es requerido')
  }),
  isRequiredPurchaseRequestLetterSigned: Yup.boolean(),
  purchaseRequestLetterSignedName: Yup.array().when(
    ['isRequiredPurchaseRequestLetterSigned', 'paymentVerification'],
    {
      is: (isRequired, paymentVerification) =>
        isRequired === true && paymentVerification === 'APPROVED',
      then: Yup.array()
        .min(1, 'Se requieren al Menos 1 documento')
        .required('El documento es requerido')
    }
  ),
  isRequiredDateSubmissionReceipts: Yup.boolean(),
  dateSubmissionReceipts: Yup.string().when(
    'isRequiredDateSubmissionReceipts',
    {
      is: true,
      then: Yup.string().required(
        'La fecha de envío de comprobantes es requerida'
      )
    }
  ),
  isRequiredDateSubmissionReceiptsArr: Yup.boolean(),
  dateSubmissionReceiptsArr: Yup.array().when(
    'isRequiredDateSubmissionReceiptsArr',
    {
      is: true,
      then: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string().required('El ID es requerido'), // Ajusta según el tipo de ID
            date: Yup.string().required('La fecha es requerida')
          })
        )
        .min(1, 'Al menos una fecha es requerida')
    }
  ),
  isRequiredDateCertificateRequest: Yup.boolean(),
  dateCertificateRequest: Yup.string().when(
    'isRequiredDateCertificateRequest',
    {
      is: true,
      then: Yup.string().required(
        'La fecha de solicitud de certificado es requerida'
      )
    }
  ),
  isRequiredDateCertificateReception: Yup.boolean(),
  dateCertificateReception: Yup.string().when(
    'isRequiredDateCertificateReception',
    {
      is: true,
      then: Yup.string().required(
        'La fecha de recepción de certificado es requerida'
      )
    }
  ),
  isRequiredDateSend: Yup.boolean(),
  dateSend: Yup.string().when('isRequiredDateSend', {
    is: true,
    then: Yup.string().required('La fecha de envío es requerida')
  }),
  isRequiredDocumentsSentMedicalInstitution: Yup.boolean(),
  documentsSentMedicalInstitutionName: Yup.array().when(
    'isRequiredDocumentsSentMedicalInstitution',
    {
      is: true,
      then: Yup.array()
        .min(1, 'Se requieren al Menos 1 documento')
        .required('El documento es requerido')
    }
  ),
  /* data Monitor  */
  isRequiredPaymentMonitors: Yup.boolean(),
  classScheduleNames: Yup.array().when('isRequiredPaymentMonitors', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El docuemnto es requerido')
  }),
  feeBillNames: Yup.array(),
  // .when('isRequiredPaymentMonitors', {
  //   is: true,
  //   then: Yup.array()
  //     .min(1, 'Se requieren al Menos 1 documento')
  //     .required('El docuemnto es requerido')
  // })
  attendanceSheetNames: Yup.array().when('isRequiredPaymentMonitors', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El docuemnto es requerido')
  }),
  isRequiredEntryDate: Yup.boolean(),
  entryDate: Yup.string().when('isRequiredEntryDate', {
    is: true,
    then: Yup.string().required('La fecha de entrada es requerida')
  }),
  entryHour: Yup.string().when('isRequiredEntryDate', {
    is: true,
    then: Yup.string().required('La hora de entrada es requerida')
  }),
  entryMinute: Yup.string().when('isRequiredEntryDate', {
    is: true,
    then: Yup.string().required('El minuto de entrada es requerido')
  }),
  entryPeriod: Yup.string().when('isRequiredEntryDate', {
    is: true,
    then: Yup.string().required('El periodo de entrada es requerido')
  }),
  canImcompleteStep: Yup.boolean(),
  incompleteStep: Yup.boolean(),
  /* end data Monitor  */
  isRequiredCommission: Yup.boolean(),
  commission: Yup.string().when('isRequiredCommission', {
    is: true,
    then: Yup.string().required('La comisión es requerida')
  }),
  supplies: Yup.boolean(),
  tariffDifference: Yup.boolean(),
  medicines: Yup.boolean(),
  milkFormula: Yup.boolean(),
  others: Yup.boolean(),
  assistanceBenefits: Yup.boolean(),
  someSelectedProcess: Yup.boolean().when(
    [
      'supplies',
      'tariffDifference',
      'medicines',
      'milkFormula',
      'others',
      'assistanceBenefits'
    ],
    {
      is: (
        supplies,
        tariffDifference,
        medicines,
        milkFormula,
        others,
        assistanceBenefits
      ) =>
        Boolean(
          supplies ||
            tariffDifference ||
            medicines ||
            milkFormula ||
            others ||
            assistanceBenefits
        ) === false,
      then: Yup.boolean().oneOf(
        [true],
        'Es requerido seleccionar al menos un proceso'
      ),
      otherwise: Yup.boolean()
    }
  ),
  isRequiredTariffDifferenceDocuments: Yup.boolean(),
  tariffDifferenceDocumentsNames: Yup.array().when(
    'isRequiredTariffDifferenceDocuments',
    {
      is: true,
      then: Yup.array()
        .min(1, 'Se requieren al Menos 1 documento')
        .required('Se requieren al Menos 1 documento')
    }
  ),
  isRequiredPurchaseOrderVerification: Yup.boolean(),
  purchaseOrderVerification: Yup.string().when(
    'isRequiredPurchaseOrderVerification',
    {
      is: true,
      then: Yup.string().required(
        'La verificación de orden de compra es requerida'
      )
    }
  )
})
