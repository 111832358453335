import React from 'react'
import { useHistory } from 'react-router-dom'
import { Flex, Text } from '@chakra-ui/react'
import { Button, CardStatus } from '../UI'
import { RedirectWhiteIcon } from '../Icons'
import { GetDiffDates } from '../../Utils'
import CardProgramInfo from '../CardProgramInfo'
import InjuredInLinesOfServiceKit from './InjuredInLinesOfServiceKit'
import SolidarityHealthFund from './SolidarityHealthFund'
import PreUniversityScholarships from './PreUniversityScholarships'
import NewbornBenefit from './NewbornBenefit'
import SupportInCaseOfDeathOfMember from './SupportInCaseOfDeathOfMember'
import DisasterSupport from './DisasterSupport'
import FireSupport from './FireSupport'
import UniversityScholarships from './UniversityScholarships'
import TechnicalProfessionalScholarships from './TechnicalProfessionalScholarships'
import ScholarshipsApplicantOfficer from './ScholarshipsApplicantOfficer'
import ScholarshipsApplicantOfficerDac from './ScholarshipsApplicantOfficerDac'
import WorkshopsAndTraining from './WorkshopsAndTraining'
import HospitalAccommodation from './HospitalAccommodation'

const DataViewPrograms = ({ type, program }) => {
  const history = useHistory()

  const programDictionary = {
    SolidarityHealthFund: <SolidarityHealthFund program={program} />,
    InjuredInLinesOfServiceKit: (
      <InjuredInLinesOfServiceKit program={program} />
    ),
    PreUniversityScholarships: <PreUniversityScholarships program={program} />,
    NewbornBenefit: <NewbornBenefit program={program} />,
    SupportInCaseOfDeathOfMember: (
      <SupportInCaseOfDeathOfMember program={program} />
    ),
    DisasterSupport: <DisasterSupport program={program} />,
    FireSupport: <FireSupport program={program} />,
    UniversityScholarships: <UniversityScholarships program={program} />,
    TechnicalProfessionalScholarships: (
      <TechnicalProfessionalScholarships program={program} />
    ),
    ScholarshipsApplicantOfficer: (
      <ScholarshipsApplicantOfficer program={program} />
    ),
    ScholarshipsApplicantOfficerDac: (
      <ScholarshipsApplicantOfficerDac program={program} />
    ),
    WorkshopsAndTraining: <WorkshopsAndTraining program={program} />,
    HospitalAccommodation: <HospitalAccommodation program={program} />
  }

  const toProfilePartner = () => {
    history.push(`/partners/profile/${program?.partner?._id}/Perfil de Socio`)
  }

  const validateFullName = (name, createdBy) => {
    if (!name) {
      return `${createdBy.name} ${createdBy.lastName}`
    }
    return name
  }

  return (
    <Flex direction={'column'} gap={2}>
      <CardProgramInfo
        createdAt={program?.receptionDate}
        fullName={validateFullName(program?.partner?.name, program?.createdBy)}
        state={program?.partner?.state}
      />

      {program?.partner && (
        <>
          {' '}
          <Flex
            direction={{
              lg: 'row',
              md: 'column',
              sm: 'column'
            }}
            minW={'100%'}
            my={2}
            gap={2}
          >
            <Flex
              w={'-webkit-fill-available'}
              bg={'green.100'}
              borderRadius={8}
              align={'center'}
              px={5}
            >
              <Text fontWeight={700} color={'white'} fontSize={20}>
                Información del socio
              </Text>
            </Flex>
            <Button
              py={5}
              mx={0}
              icon={RedirectWhiteIcon}
              text={'Ver perfil'}
              onClick={toProfilePartner}
            />
          </Flex>
          <CardStatus
            subValue={'Antigüedad'}
            value={
              program?.partner?.insertDate
                ? GetDiffDates(program?.partner?.insertDate)
                : 'Dato no proporcionado'
            }
            bg={program?.partner?.insertDate ? 'green.50' : 'yellow.50'}
            color={program?.partner?.insertDate ? 'green.500' : 'yellow.500'}
          />
        </>
      )}

      {programDictionary[type]}
    </Flex>
  )
}

export default DataViewPrograms
