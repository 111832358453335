import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { Flex, useDisclosure, useToast } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import ModalHistory from '../../ModalHistory'
import Question from './Question'
import TableProgram from './TableProgram'
import Confirmation from './Confirmation'
import Form from './Form'
import { Button, CustomToast } from '../../../UI'
import {
  GetNotification,
  GetFormData,
  GetKey,
  CondicionalModal
} from '../../../../Utils'
import { openResignationModal } from '../../../../store/slices/modal'
import { useTypeModal } from '../../../../hooks/TypeModal'
import { useMutateHistoryProgram } from '../../../../hooks/HistoryProgram'
import { schema } from './Schema'

const newState = {
  REJECTED: 'COMPLETED-REJECTED',
  APPROVED: 'COMPLETED-APPROVED',
  REVOKED: 'COMPLETED-REVOKED',
  DESISTED: 'DESISTED'
}

const MultiProgramProcesses = ({ currentStep, getColor, program }) => {
  const toast = useToast()
  const dispatch = useDispatch()
  const {
    requiredDocuments,
    requiredDate,
    requiredPayment,
    requiredAuthorization,
    requiredProposedState,
    requiredAmount,
    requiredOfficeStatus,
    requiredVoucher,
    requiredInstructive,
    requiredTransferredAmount,
    requiredAmountCancel,
    requiredPurchaseDetail,
    requiredOffice,
    requiredInvoice,
    requiredVadiationOffice,
    requiredReportedEmail,
    requiredActa,
    canImcompleteStep,
    canDesistProgram
  } = CondicionalModal

  const { actionKey, succesMessage, errorMessage } = useTypeModal(
    currentStep?.key
  )
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [flowStep, setFlowStep] = useState(1)
  const [stepsTitlesProgram, setStepsTitlesProgram] = useState({})
  const [addAll, setAddAll] = useState(false)
  const { mutate, isLoading, reset } = useMutateHistoryProgram(
    currentStep?.key === 'END-PROCESS' ? 'MULTI-PROGRAM-PROCESSES' : actionKey
  )

  const initialValues = {
    programsIds: [],
    stepProgram: currentStep?.step,
    descriptions: '',
    isRequiredDate: false,
    dateDelivery: '',
    isRequiredDocuments: false,
    documentsNames: [],
    documents: null,
    isRequiredPayment: false,
    paymentVerification: '',
    isRequiredAuthorization: false,
    orderAuthorization: '',
    isReuiredAlterProcess: false,
    alterProcess: '',
    completedSemesters: program?.completedSemesters || 0,
    isRequiredProposedState: false,
    proposedState: '',
    isRequiredAmount: false,
    amount: '',
    isRequiredOfficeStatus: false,
    officeStatus: '',
    isRequiredVoucher: false,
    voucherName: [],
    voucher: null,
    isRequiredInstructive: false,
    stockOfficeName: [],
    stockOffice: null,
    deliveryProofName: [],
    deliveryProof: null,
    instructiveName: [],
    instructive: null,
    isRequiredTransferredAmount: false,
    transferredAmount: '',
    isRequiredOffice: false,
    officeName: [],
    office: null,
    isRequiredInvoice: false,
    invoiceName: [],
    invoice: null,
    isRequiredValidationOffice: false,
    validationOffice: '',
    isRequiredReportedEmail: false,
    reportedEmailName: [],
    reportedEmail: null,
    /* --- */
    isRequiredAmountCancel: false,
    amountCancelName: [],
    amountCancel: null,
    isRequiredPurchaseDetail: false,
    purchaseDetailName: [],
    purchaseDetail: null,
    isRequiredActa: false,
    actaName: [],
    acta: null,
    program: program?._id,
    keyHistory: GetKey.getNextStep(
      currentStep?.key,
      program?.type
    ) /* es el siguiente paso */,
    state: program?.state,
    canImcompleteStep: false,
    canDesistProgram: canDesistProgram[program.type]
      ? canDesistProgram[program.type].includes(currentStep?.key)
      : false,
    incompleteStep: false
  }
  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    validateOnChange: false,
    onSubmit: (values) => {
      const formatedValues = {
        ...values,
        programsIds: JSON.stringify(values.programsIds)
      }

      if (currentStep?.key === 'END-PROCESS') {
        formatedValues.keyHistory = GetKey.getFinalKey[program?.type]
        formatedValues.state = newState[program?.state]
      }

      const formData = GetFormData(formatedValues, [
        'documents',
        'voucher',
        'stockOffice',
        'deliveryProof',
        'instructive',
        'backupFeeAmount',
        'scholarshipCommission',
        'bankData',
        'office',
        'invoice',
        'classSchedule',
        'feeBill',
        'attendanceSheet',
        'amountCancel',
        'purchaseDetail',
        'acta',
        'reportedEmail'
      ])
      mutate(formData, {
        onSuccess: (data) => {
          reset()
          onClose()
          formik.resetForm()
          GetNotification.basic(
            `${succesMessage} ${data.totalUpdated || ''}`,
            'Aceptar'
          )
        },
        onError: (err) => {
          console.log(err)
          onClose()
          const response = err?.response?.data

          GetNotification.basic(
            'Error',
            'Aceptar',
            response?.type === 'validateLastStep'
              ? response.message
              : errorMessage,
            'error'
          )
        }
      })
    }
  })

  const textLeftButton = {
    1: 'No, Solo el Actual',
    2: 'Cancelar',
    3: 'Atrás',
    4: 'Atrás'
  }

  const textRightButton = {
    1: 'Si, Todos',
    2: 'Continuar',
    3: 'Continuar',
    4: 'Aceptar'
  }

  const NextModalStep = () => {
    if (flowStep === 1) {
      setAddAll(true)
      setFlowStep(flowStep + 1)
    }

    if (flowStep < 4) {
      setFlowStep(flowStep + 1)
    }
    if (flowStep === 4) {
      formik.handleSubmit()
    }
  }

  const backModalStep = () => {
    if (flowStep === 2) {
      onClose()
    }
    if (flowStep > 1) {
      setFlowStep(flowStep - 1)
    }
    if (flowStep === 1) {
      formik.setValues({
        ...formik.values,
        programsIds: [program._id]
      })
      setFlowStep(flowStep + 1)
    }
  }

  const selectedProgramChangeCheckBox = (e, programId) => {
    const isChecked = e.target.checked

    if (isChecked) {
      formik.setFieldValue('programsIds', [
        ...formik.values.programsIds,
        programId
      ])
    } else {
      formik.setFieldValue(
        'programsIds',
        formik.values.programsIds.filter((item) => item !== programId)
      )
    }
  }

  const addValidationsinForm = () => {
    const validation = {
      isRequiredDate: requiredDate.includes(currentStep?.key),
      isRequiredDocuments: requiredDocuments.includes(currentStep?.key),
      isRequiredPayment: requiredPayment.includes(currentStep?.key),
      isRequiredAuthorization: requiredAuthorization.includes(currentStep?.key),
      isRequiredProposedState: requiredProposedState.includes(currentStep?.key),
      isRequiredAmount: requiredAmount.includes(currentStep?.key),
      isRequiredOfficeStatus: requiredOfficeStatus.includes(currentStep?.key),
      isRequiredVoucher: requiredVoucher.includes(currentStep?.key),
      isRequiredInstructive: requiredInstructive.includes(currentStep?.key),

      isRequiredTransferredAmount: requiredTransferredAmount.includes(
        currentStep?.key
      ),
      isRequiredAmountCancel: requiredAmountCancel.includes(currentStep?.key),
      isRequiredPurchaseDetail: requiredPurchaseDetail.includes(
        currentStep?.key
      ),
      isRequiredOffice: requiredOffice.includes(currentStep?.key),
      isRequiredInvoice: requiredInvoice.includes(currentStep?.key),
      isRequiredValidationOffice: requiredVadiationOffice.includes(
        currentStep?.key
      ),
      isRequiredReportedEmail: requiredReportedEmail.includes(currentStep?.key),
      isRequiredActa: requiredActa.includes(currentStep?.key),
      canImcompleteStep: canImcompleteStep.includes(currentStep?.key)
    }
    return validation
  }

  useEffect(() => {
    if (formik.values.incompleteStep) {
      formik.setValues({
        ...initialValues,
        programsIds: formik.values.programsIds,
        keyHistory: formik.values.keyHistory,
        canImcompleteStep: formik.values.canImcompleteStep,
        incompleteStep: formik.values.incompleteStep
      })
    } else {
      formik.setValues({
        ...formik.values,
        ...addValidationsinForm()
      })
    }
  }, [formik.values.incompleteStep])

  useEffect(() => {
    if (isOpen) {
      formik.resetForm(initialValues)
      setFlowStep(1)
      setAddAll(false)

      /* --- */
      formik.setValues({
        ...initialValues,
        ...addValidationsinForm()
      })
    }
  }, [isOpen])

  useEffect(() => {
    if (
      formik.values.documentsNames.length > 0 &&
      !formik.values.isRequiredDocuments
    ) {
      formik.setValues({
        ...formik.values,
        documentsNames: [],
        documents: null
      })
    }
  }, [formik.values.isRequiredDocuments])

  useEffect(() => {
    if (isLoading) {
      toast({
        duration: 9000 * 9000,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose: onCloseT }) => (
          <CustomToast
            text={'Espere por favor...'}
            status={'info'}
            onClose={onCloseT}
          />
        )
      })
      return
    }
    toast.closeAll()
  }, [isLoading])

  return (
    <ModalHistory
      colors={getColor}
      disable={currentStep?.complete || isLoading}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      size={'4xl'}
      showButtons={false}
      isLoading={isLoading}
    >
      <Flex direction={'column'} align={'center'} justify={'center'} gap={2}>
        {flowStep === 1 && <Question currentStep={currentStep} />}
        {flowStep === 2 && (
          <TableProgram
            programsIds={formik.values.programsIds}
            addAll={addAll}
            formik={formik}
            selectedProgramChangeCheckBox={selectedProgramChangeCheckBox}
            type={program.type}
            state={program.state}
            currentStep={currentStep.key}
            setStepsTitlesProgram={setStepsTitlesProgram}
          />
        )}
        {flowStep === 3 && (
          <Confirmation
            stepsTitlesProgram={stepsTitlesProgram}
            currentStep={currentStep}
          />
        )}
        {flowStep === 4 && (
          <Form program={program} formik={formik} currentStep={currentStep} />
        )}
        <Flex align={'center'} justify={'center'} gap={2}>
          {formik.values.canDesistProgram && (
            <Button
              text="Desistir"
              bg="red.500"
              bghover="red.600"
              onClick={() => {
                onClose()
                dispatch(openResignationModal())
              }}
            />
          )}
          <Button
            text={textLeftButton[flowStep]}
            bg={'beige.500'}
            color={'green.500'}
            bghover={'beige.600'}
            onClick={backModalStep}
            disabled={isLoading}
          />
          <Button
            text={textRightButton[flowStep]}
            disabled={
              (flowStep === 2 && formik.values?.programsIds?.length === 0) ||
              isLoading
            }
            bg={'green.500'}
            onClick={NextModalStep}
            isLoading={isLoading}
          />
        </Flex>
      </Flex>
    </ModalHistory>
  )
}

export default MultiProgramProcesses
