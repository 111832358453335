import React from 'react'
import { Text, Flex, Icon } from '@chakra-ui/react'
import { WarningIcon } from '../Icons'

const NotAllowedMessage = () => (
  <Flex
    bg={
      'linear-gradient(18deg, rgba(131,58,180,0) 0%, rgba(252,216,69,0.208018241476278) 100%)'
    }
    border={'1px dotted #fcd845'}
    direction={'column'}
    justify={'center'}
    align={'center'}
    borderRadius={12}
    gap={2}
    p={4}
  >
    <Text fontSize={'xl'} fontWeight={700} color={'green.500'}>
      Advertencia
    </Text>
    <Text fontSize={'md'} color={'green.500'}>
      El Socio ya tiene una postulación de este tipo en proceso.
    </Text>
    {/* <Text fontSize={'sm'} color={'green.500'}>
      Favor seleccionar otro tipo de postulación
    </Text> */}
    <Icon as={WarningIcon} fontSize={24} color={'yellow.500'} />
  </Flex>
)

export default NotAllowedMessage
