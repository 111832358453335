import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Grid, GridItem, Text, Flex } from '@chakra-ui/react'
import { Select2, CardStatus } from '../UI'
import { ProgramsList } from '../../Utils'
import InjuredInLinesOfServiceKit from './InjuredInLinesOfServiceKit'
import PreUniversityScholarships from './PreUniversityScholarships'
import NewbornBenefit from './NewbornBenefit'
import SupportInCaseOfDeathOfMember from './SupportInCaseOfDeathOfMember'
import DisasterSupport from './DisasterSupport'
import FireSupport from './FireSupport'
import UniversityScholarships from './UniversityScholarships'
import TechnicalProfessionalScholarships from './TechnicalProfessionalScholarships'
import ScholarshipsApplicantOfficer from './ScholarshipsApplicantOfficer'
import ScholarshipsApplicantOfficerDAC from './ScholarshipsApplicantOfficerDac'
import HospitalAccommodation from './HospitalAccommodation'
import SolidarityHealthFund from './SolidarityHealthFund'
import NotAllowedMessage from './NotAllowedMessage'

const FormContainerPrograms = ({ partner, program: programUpdate }) => {
  const { user } = useSelector((state) => state.auth)
  const [program, setProgram] = useState('')
  const [programName, setProgramName] = useState('')

  const programDictionary = {
    InjuredInLinesOfServiceKit: (
      <InjuredInLinesOfServiceKit partner={partner} program={programUpdate} />
    ),
    PreUniversityScholarships: (
      <PreUniversityScholarships partner={partner} program={programUpdate} />
    ),
    NewbornBenefit: (
      <NewbornBenefit partner={partner} program={programUpdate} />
    ),
    SupportInCaseOfDeathOfMember: (
      <SupportInCaseOfDeathOfMember partner={partner} program={programUpdate} />
    ),
    DisasterSupport: (
      <DisasterSupport partner={partner} program={programUpdate} />
    ),
    FireSupport: <FireSupport partner={partner} program={programUpdate} />,
    UniversityScholarships: (
      <UniversityScholarships partner={partner} program={programUpdate} />
    ),
    TechnicalProfessionalScholarships: (
      <TechnicalProfessionalScholarships
        partner={partner}
        program={programUpdate}
      />
    ),
    ScholarshipsApplicantOfficer: (
      <ScholarshipsApplicantOfficer partner={partner} program={programUpdate} />
    ),
    ScholarshipsApplicantOfficerDac: (
      <ScholarshipsApplicantOfficerDAC
        partner={partner}
        program={programUpdate}
      />
    ),
    HospitalAccommodation: (
      <HospitalAccommodation partner={partner} program={programUpdate} />
    ),
    SolidarityHealthFund: (
      <SolidarityHealthFund partner={partner} program={programUpdate} />
    )
  }

  const filteredProgramsList = () => {
    const { userType } = user
    if (!userType?.includes('ALL')) {
      return ProgramsList?.filter((item) =>
        userType?.includes(item.key)
      ).filter((item) => item?.key !== 'WorkshopsAndTraining')
    }
    return ProgramsList?.filter((item) => item?.key !== 'WorkshopsAndTraining')
  }

  useEffect(() => {
    if (program !== '') {
      const i = ProgramsList.findIndex((item) => item.key === program)
      setProgramName(ProgramsList[i].name)
    }
  }, [program])

  useEffect(() => {
    if (programUpdate) {
      setProgram(programUpdate.type)
    }
  }, [programUpdate])

  return (
    <Grid
      templateColumns={{
        lg: 'repeat(1, 100%)'
      }}
      gap={2}
      px={6}
    >
      <GridItem>
        <Flex direction={'column'} gap={2}>
          <Text fontWeight={700} fontSize={'3xl'} color={'green.500'}>
            Solicitud de Beneficio
          </Text>
          <Flex gap={2} direction={'column'}>
            <Flex direction={'column'}>
              <Text fontWeight={700} fontSize={'md'} color={'grey.500'}>
                Seleccione Programa
              </Text>
              <Select2
                placeHolder={'<Seleccione>'}
                list={filteredProgramsList()}
                bg={'white'}
                onChange={(value) => setProgram(value)}
                value={program}
                disabled={programUpdate}
              />
            </Flex>
            <CardStatus value={programName} opacity={program !== '' ? 1 : 0} />
          </Flex>
        </Flex>
      </GridItem>
      <GridItem>
        {partner?.activeProgramsKeys?.includes(program) && (
          <NotAllowedMessage />
        )}
        {programDictionary[program]}
      </GridItem>
    </Grid>
  )
}

export default FormContainerPrograms
