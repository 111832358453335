import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Menu as MenuCha,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Button,
  useToast
} from '@chakra-ui/react'
import {
  EditIcon,
  CanceledIcon,
  TrashIcon,
  OptionsIcons
} from '../../../components/Icons'
import { GetNotification } from '../../../Utils'
import { useMutateHistoryProgram } from '../../../hooks/HistoryProgram'
import { CustomToast } from '../../../components/UI'
import { openResignationModal } from '../../../store/slices/modal'

const OptionsMenu = ({ program }) => {
  const { user } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const history = useHistory()
  const toast = useToast()
  const { mutate, isLoading } = useMutateHistoryProgram(
    'DELETE-HISTORY-AND-PROGRAM'
  )

  const redirectProgramTypeDictionary = {
    HospitalAccommodation: () =>
      history.push(`/programs/edit/${program?._id}/${program?.type}`),
    SolidarityHealthFund: () =>
      history.push(`/programs/edit/${program?._id}/${program?.type}`),
    InjuredInLinesOfServiceKit: () =>
      history.push(`/programs/edit/${program?._id}/${program?.type}`),
    FireSupport: () =>
      history.push(`/programs/edit/${program?._id}/${program?.type}`),
    DisasterSupport: () =>
      history.push(`/programs/edit/${program?._id}/${program?.type}`),
    SupportInCaseOfDeathOfMember: () =>
      history.push(`/programs/edit/${program?._id}/${program?.type}`),
    NewbornBenefit: () =>
      history.push(`/programs/edit/${program?._id}/${program?.type}`),
    PreUniversityScholarships: () =>
      history.push(`/programs/edit/${program?._id}/${program?.type}`),
    TechnicalProfessionalScholarships: () =>
      history.push(`/programs/edit/${program?._id}/${program?.type}`),
    ScholarshipsApplicantOfficer: () =>
      history.push(`/programs/edit/${program?._id}/${program?.type}`),
    ScholarshipsApplicantOfficerDac: () =>
      history.push(`/programs/edit/${program?._id}/${program?.type}`),
    UniversityScholarships: () =>
      history.push(`/programs/edit/${program?._id}/${program?.type}`),
    WorkshopsAndTraining: () =>
      history.push(`/programs/edit-workshops-and-training/${program?._id}`)
  }

  const openResignationOrLossModal = () => {
    dispatch(openResignationModal())
  }

  const isDisableOptionResignationOrLoss = () => {
    const { state, steps } = program
    const isFinished = Boolean(
      state === 'COMPLETED-APPROVED' ||
        state === 'DELETED' ||
        state === 'COMPLETED-REJECTED' ||
        state === 'COMPLETED-REVOKED' ||
        state === 'REVOKED' ||
        state === 'REJECTED'
    )
    const hasActiveStepOne = Boolean(
      steps?.filter(
        (step) =>
          step?.key === 'RESIGNATION-OR-LOSS-AUTHORIZATION-RESIGNATION' &&
          !step?.complete
      ).length > 0
    )
    const hasActiveStepTwo = Boolean(
      steps?.filter(
        (step) => step?.key === 'CORRECT-REQUEST-RESIGNATION' && !step?.complete
      ).length > 0
    )
    // const hasActiveStepThree = Boolean(
    //   steps?.filter((step) => step?.key === 'END-PROCESS' && !step?.complete)
    //     .length > 0
    // )
    const hasActiveStepFour = Boolean(
      steps?.filter((step) => step?.key === 'FINISHED' && !step?.complete)
        .length > 0
    )

    if (
      isFinished ||
      hasActiveStepOne ||
      hasActiveStepTwo ||
      hasActiveStepFour
    ) {
      return true
    }
    return false
  }

  const mutatedeleteProgram = () => {
    mutate(program._id, {
      onSuccess: () => {
        GetNotification.basic('Programa Eliminado con Éxito', 'Aceptar')
        history.push('/programs')
      },
      onError: (err) => {
        console.log(err)
        GetNotification.basic(
          'Error',
          'Aceptar',
          'Error al Eliminar programa',
          'error'
        )
      }
    })
  }

  const questionDeleteProgram = () => {
    GetNotification.question(
      '¿Estás seguro de Eliminar el Programa?',
      () => mutatedeleteProgram(),
      null,
      '',
      'question'
    )
  }

  const optionsMenu = [
    {
      text: 'Editar',
      icon: EditIcon,
      function: redirectProgramTypeDictionary[program?.type],
      disabled: Boolean(
        program?.state === 'APPROVED' ||
          program?.state === 'COMPLETED-APPROVED' ||
          program?.state === 'DELETED' ||
          program?.state === 'COMPLETED-REJECTED' ||
          program?.state === 'REJECTED'
      )
    },
    {
      text: 'Renuncia o perdida',
      icon: CanceledIcon,
      function: () => openResignationOrLossModal(),
      disabled: isDisableOptionResignationOrLoss()
    },
    {
      text: 'Eliminar',
      icon: TrashIcon,
      function: () => questionDeleteProgram(),
      disabled: Boolean(
        program?.state === 'COMPLETED-APPROVED' ||
          program?.state === 'DELETED' ||
          program?.state === 'COMPLETED-REJECTED'
      )
    }
  ]

  useEffect(() => {
    if (isLoading) {
      toast({
        duration: 9000 * 9000,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose: onCloseT }) => (
          <CustomToast
            text={'Espere por favor...'}
            status={'info'}
            onClose={onCloseT}
          />
        )
      })
      return
    }
    toast.closeAll()
  }, [isLoading])

  return (
    <>
      {user.role !== 'REVISION' ? (
        <MenuCha isLazy>
          <MenuButton>
            <Icon fontSize="22" as={OptionsIcons} p={1} />
          </MenuButton>
          <MenuList
            borderRadius={20}
            py={3}
            px={3}
            color={'green.500'}
            zIndex={999999999}
          >
            {optionsMenu.map((item) => (
              <MenuItem key={item.text} p={0} _hover={{ bg: 'transparents' }}>
                <Button
                  w={'100%'}
                  bg={'transparent'}
                  justifyContent={'flex-start'}
                  borderRadius={20}
                  leftIcon={<Icon as={item.icon} />}
                  _hover={{ bg: 'green.50' }}
                  onClick={item?.function}
                  mb={1}
                  disabled={item.disabled}
                >
                  {item?.text}
                </Button>
              </MenuItem>
            ))}
          </MenuList>
        </MenuCha>
      ) : (
        <></>
      )}
    </>
  )
}

export default OptionsMenu
