import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { Button } from '../../../UI'

const ButtonOptions = ({
  onClickLeft,
  onClickRight,
  onClickExtraButton,
  value,
  error,
  buttonsProps
}) => (
  <Flex align={'center'} justify={'center'} direction={'column'} gap={5}>
    <Flex w={'100%'} justify={'center'}>
      {buttonsProps?.textRightExtra && (
        <Button
          text={buttonsProps?.textRightExtra}
          icon={buttonsProps?.iconRightExtra}
          bg={`${buttonsProps?.colorLeftExtra}.500`}
          opacity={!buttonsProps?.optionRightExtra?.includes(value) ? 0.5 : 1}
          bghover={`${buttonsProps?.colorLeftExtra}.100`}
          onClick={onClickExtraButton}
        />
      )}
      {buttonsProps?.textLeft && (
        <Button
          text={buttonsProps?.textLeft}
          icon={buttonsProps?.iconLeft}
          bg={`${buttonsProps?.colorLeft}.500`}
          bghover={`${buttonsProps?.colorLeft}.100`}
          opacity={!buttonsProps?.optionLeft?.includes(value) ? 0.5 : 1}
          onClick={onClickLeft}
        />
      )}
      <Button
        text={buttonsProps?.textRight}
        icon={buttonsProps?.iconRight}
        opacity={!buttonsProps?.optionRight?.includes(value) ? 0.5 : 1}
        onClick={onClickRight}
      />
    </Flex>
    <Flex w={'100%'} justify={'center'} opacity={error ? 1 : 0}>
      <Text color={'red.500'}>{error || ''}</Text>
    </Flex>
  </Flex>
)

export default ButtonOptions
