const canChangeState = [
  'PRESIDENSY-DETERMINATION-DISASTER-SUPPORT' /* beneficio apoyo en Catástrofe  */,
  'PRESIDENSY-DETERMINATION-FIRE-SUPPORT' /* beneficio apoyo en INCENDIO  */,
  'MANAGEMENT-DETERMINATION-WORKSHOPS-AND-TRAINING' /* Telleres y capacitaciones  */,
  /* 'EVALUATION-COMMISSION-SOLIDARITY-HEALTH-FUND', */
  'EVALUATION-COMMISSION-UNIVERSITY' /* beneficio universitario */,
  'EVALUATION-COMMISSION-APPLICANT-OFFICER' /* beneficio universitario */,
  'EVALUATION-COMMISSION-APPLICANT-OFFICER-DAC' /* beneficio universitario */,
  'EVALUATION-COMMISSION-TECHNICAL-PROFESSIONAL' /* beneficio universitario */,
  'EVALUATION-COMMISSION-PRE-UNIVERSITY' /* beneficio PRE universitario */,
  'EVALUATION-CONTROL-SOCIAL-MANAGEMENT' /*  */,
  'EVALUATION-CONTROL-SOCIAL-MANAGEMENT-DEATH-OF-MEMBER' /* fallecimiento de socio */,
  'RESIGNATION-OR-LOSS-AUTHORIZATION-RESIGNATION' /* resignation or loss */,
  'EVALUATION-COMMISSION-NEW-SEMESTERS' /* beneficio tecnico profesional */,
  'AUTHORIZE-REQUEST-PAYMENT-ONE-DEATH-OF-MEMBER',
  'AUTHORIZE-REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER'
  /* 'EVALUATION-CONTROL-SOCIAL-MANAGEMENT-NEWBORN-BENEFIT' */
]

const canChangeKeyhistory = [
  'EVALUATION-COMMISSION-SOLIDARITY-HEALTH-FUND',
  'EVALUATION-COMMISSION-UNIVERSITY',
  'EVALUATION-COMMISSION-APPLICANT-OFFICER',
  'EVALUATION-COMMISSION-APPLICANT-OFFICER-DAC',
  'EVALUATION-COMMISSION-PRE-UNIVERSITY',
  'EVALUATION-COMMISSION-TECHNICAL-PROFESSIONAL'
]

const canImcompleteStep = [
  'NOTIFICATION-ACT-SIGNED-BY-PARTNER-AND-SCHOLARSHIP-RECIPIENT' /* beca universitaria */,
  // 'NOTIFICATION-ACT-SIGNED-BY-PARTNER-AND-SCHOLARSHIP-RECIPIENT-UNIVERSITY' /* beca universitaria */,
  'NOTIFICATION-ACT-SIGNED-BY-PARTNER-AND-SCHOLARSHIP-RECIPIENT-TECHNICAL-PROFESSIONAL' /* beca universitaria */,
  'PURCHASE-ORDER-REQUEST' /* beca pre universitaria */,
  'PURCHASE-ORDER-REQUEST-EDIT' /* beca pre universitaria */,
  'PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER' /* beca pre universitaria */,
  'PURCHASE-ORDER-REQUEST-EDIT-APPLICANT-OFFICER' /* beca pre universitaria */,
  'PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC' /* beca pre universitaria */,
  'PURCHASE-ORDER-REQUEST-EDIT-APPLICANT-OFFICER-DAC' /* beca pre universitaria */,
  'PURCHASE-ORDER-REQUEST-PRE-UNIVERSITY' /* beca pre universitaria */,
  'PURCHASE-ORDER-REQUEST-EDIT-PRE-UNIVERSITY' /* beca pre universitaria */
]

const canDesistProgram = {
  TechnicalProfessionalScholarships: [
    'NOTIFICATION-ACT-SIGNED-BY-PARTNER-AND-SCHOLARSHIP-RECIPIENT-TECHNICAL-PROFESSIONAL'
  ] /* beca universitaria */,
  SolidarityHealthFund: [
    'NOTIFY-NEW-STATUS',
    'ALL-INVOICES-AND-TRADES-SOLIDARITY-HEALTH-FUND'
  ] /* Fondo solidario de salud */,
  HospitalAccommodation: [
    'ADD-STAY-CERTIFICATE-HOSPITAL-ACCOMMODATION'
  ] /* hospedaje hospitalario */,
  ScholarshipsApplicantOfficer: [
    'NOTIFY-NEW-STATUS'
  ] /* beca aspirante a oficial */,
  ScholarshipsApplicantOfficerDac: [
    'NOTIFY-NEW-STATUS'
  ] /* beca aspirante a oficial */,
  UniversityScholarships: [
    'PURCHASE-ORDER-REQUEST-UNIVERSITY'
  ] /* beca universitaria */,
  PreUniversityScholarships: [
    'NOTIFY-NEW-STATUS',
    'ATTACH-INVOICE-BY-PRE-UNIVERSITY'
  ] /* beca pre universitaria */
}

const requiredDescriptions = [
  'LEGAL-REPORT-DISASTER-SUPPORT' /* Apoyo en caso de CATASTROFE */,
  'LEGAL-REPORT-FIRE-SUPPORT' /* Apoyo en caso de CATASTROFE */,
  'DETERMINATION-EXTENSION-HOSPITAL-ACCOMMODATION' /* hospedaje hospitalario */
]

const requiredDocuments = [
  'GIFTCARD-DENOMINATION-REQUEST-NEWBORN-BENEFIT' /* beneficio recien nacido */,
  'GIFTCARD-DELIVERY-NEWBORN-BENEFIT' /* beneficio recien nacido */,
  'GIFTCARD-RECEPTION-NEWBORN-BENEFIT' /* beneficio recien nacido */,
  'REQUEST-PAYMENT-ONE-DEATH-OF-MEMBER' /* beneficio apoyo en caso de fallecimiento de socio  */,
  'PAYMENT-ONE-MADE-DEATH-OF-MEMBER' /* beneficio apoyo en caso de fallecimiento de socio  */,
  'REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER' /* beneficio apoyo en caso de fallecimiento de socio  */,
  'PAYMENT-TWO-MADE-DEATH-OF-MEMBER' /* beneficio apoyo en caso de fallecimiento de socio  */,
  'REQUEST-PAYMENT-ONE-EDIT-DEATH-OF-MEMBER' /* beneficio apoyo en caso de fallecimiento de socio  */,
  'REQUEST-PAYMENT-TWO-EDIT-DEATH-OF-MEMBER' /* beneficio apoyo en caso de fallecimiento de socio  */,
  'INFORM-PRE-UNIVERSITY-AND-REQUEST-PAYMENT-PRE-UNIVERSITY' /* beneficio pre universitario  */,
  'PAYMENT-MADE-PRE-UNIVERSITY' /* beneficio pre universitario  */,
  'REPORT-PAYMENT-REQUEST-INVOICE-PRE-UNIVERSITY' /* beneficio pre universitario  */,
  'GENERATE-PURCHASE-ORDER-UNIVERSITY' /* beneficio beca universitaria  */,
  'GENERATE-PURCHASE-ORDER-UNIVERSITY-EDIT-UNIVERSITY' /* beneficio beca universitaria  */,
  'SIGN-PURCHASE-ORDER-UNIVERSITY' /* beneficio beca universitaria  */,
  'DELIVERY-OFFICE-UNIVERSITY' /* beneficio beca universitaria  */,
  'DELIVERY-OFFICE-UNIVERSITY-EDIT-UNIVERSITY' /* beneficio beca universitaria  */,
  'ATTACH-INVOICE-BY-PRE-UNIVERSITY' /* beneficio pre universitario  */,
  'OFFICE-FOR-FINANCES-PRE-UNIVERSITY' /* beneficio pre universitario  */,
  'OFFICE-FOR-FINANCES-PRE-UNIVERSITY-EDIT' /* beneficio pre universitario  */,
  'VERIFICATION-PURCHASE-ORDER-REQUEST-PRE-UNIVERSITY' /* beneficio pre universitario  */,
  'SIGN-PURCHASE-ORDER-PRE-UNIVERSITY' /* beneficio pre universitario  */,
  'GENERATE-PURCHASE-ORDER-PRE-UNIVERSITY' /* beneficio pre universitario  */,
  'ADD-PROPOSAL-DISASTER-SUPPORT' /* beneficio apoyo en Catástrofe  */,
  'ADD-PROPOSAL-FIRE-SUPPORT' /* beneficio apoyo en incendio  */,
  'INFORM-SERVICE-SOCIAL-DETERMINATION' /* beneficio apoyo en Catástrofe  */,
  'OFFICE-WITH-RESOLUTION' /* beneficio apoyo en Catástrofe  */,
  'AID-APPROVAL-DISASTER-SUPPORT' /* beneficio apoyo en Catástrofe  */,
  'AID-APPROVAL-EDIT-DISASTER-SUPPORT' /* beneficio apoyo en Catástrofe  */,
  'AID-APPROVAL-FIRE-SUPPORT' /* beneficio apoyo en INCENDIO  */,
  'AID-APPROVAL-EDIT-FIRE-SUPPORT' /* beneficio apoyo en INCENDIO  */,
  // 'ACCOUNT-PERFORMANCE-DISASTER-SUPPORT' /* beneficio apoyo en Catástrofe  */,
  // 'ACCOUNT-PERFORMANCE-FIRE-SUPPORT' /* beneficio apoyo en INCENDIO  */,
  'NOTIFICATION-ACT-SIGNED-BY-PARTNER-AND-SCHOLARSHIP-RECIPIENT-TECHNICAL-PROFESSIONAL' /* beca TECNICA  */,
  // 'NOTIFICATION-ACT-SIGNED-BY-PARTNER-AND-SCHOLARSHIP-RECIPIENT-UNIVERSITY' /* beca universitaria  */,
  'CORRECT-REQUEST-PRE-UNIVERSITY' /* beca pre universitario  */,
  'PAYMENT-MADE-UNIVERSITY' /* beca universitaria  */,
  'CORRECT-REQUEST-TECHNICAL-PROFESSIONAL' /* Becas Técnico-Profesional   */,
  'CORRECT-REQUEST-TECHNICAL-PROFESSIONAL-NEW-SEMESTERS' /* Becas Técnico-Profesional   */,
  'PAYMENT-MADE-TECHNICAL-PROFESSIONAL' /* Becas Técnico-Profesional   */,
  'PAYMENT-MADE-TECHNICAL-PROFESSIONAL-NEW-SEMESTERS' /* Becas Técnico-Profesional   */,
  'PAYMENT-MADE-APPLICANT-OFFICER' /* Becas aspirante a oficial   */,
  'RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER' /* Becas aspirante a oficial   */,
  'CORRECT-REQUEST-APPLICANT-OFFICER' /* Becas aspirante a oficial   */,
  'PAYMENT-MADE-APPLICANT-OFFICER-DAC' /* Becas aspirante a oficial   */,
  'RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER-DAC' /* Becas aspirante a oficial   */,
  'CORRECT-REQUEST-APPLICANT-OFFICER-DAC' /* Becas aspirante a oficial   */,
  'PAYMENT-MADE-WORKSHOPS-AND-TRAINING' /* Talleres y capacitaciones  */,
  'ADD-OFFICE-ORDER-INUJRED-IN-LINES-OF-SERVICE-KIT' /* Kit de lesionados en actos de servicio */,
  'ADD-STAY-CERTIFICATE-HOSPITAL-ACCOMMODATION' /* hospedaje hospitalario */,
  'CORRECT-REQUEST-RESIGNATION' /* resignation or loss */,
  'EVALUATION-COMMISSION-SOLIDARITY-HEALTH-FUND' /* Fondo solidario de salud */,
  'EVALUATION-COMMISSION-UNIVERSITY' /* Becas beca aspirante a oficial */,
  'EVALUATION-COMMISSION-APPLICANT-OFFICER' /* Becas beca aspirante a oficial */,
  'EVALUATION-COMMISSION-APPLICANT-OFFICER-DAC' /* Becas beca aspirante a oficial */,
  'EVALUATION-COMMISSION-TECHNICAL-PROFESSIONAL' /* Becas beca tecnica */,
  'EVALUATION-COMMISSION-NEW-SEMESTERS' /* Becas beca tecnica nuevos semestres */,
  'EVALUATION-COMMISSION-PRE-UNIVERSITY' /* Becas PRE UNIVERSITARIO */,
  'SING-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER' /* Becas aspirante a oficial */,
  'SING-RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER' /* Becas aspirante a oficial */,
  'SING-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC' /* Becas aspirante a oficial */,
  'SING-RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER-DAC' /* Becas aspirante a oficial */,
  'RE-UPLOAD-DENOMINATION-REQUEST-NEWBORN-BENEFIT' /* beneficio recien nacido */,
  'USED-AMOUNT-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'GENERATE-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'SIGN-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'INFORM-THE-PAYMENT-MADE-TWO-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  // 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  // 'VERIFICATION-DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  // 'VERIFICATION-DELIVERY-OFFICE-MULTI-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'DELIVERY-AND-RECEIPT-CERTIFICATE-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'DELIVERY-AND-RECEIPT-CERTIFICATE-SIGN-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'INFORM-THE-PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'INFORM-THE-PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'VERIFICATION-PURCHASE-ORDER-REQUEST-TECHNICAL-PROFESSIONAL' /* Besa tecnico */,
  'VERIFICATION-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER' /* beneficio pre universitario  */,
  'PURCHASE-ORDER-GENERATE-APPLICANT-OFFICER' /* beneficio pre pre universitario  */,
  'VERIFICATION-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC' /* beneficio pre universitario  */,
  'PURCHASE-ORDER-GENERATE-APPLICANT-OFFICER-DAC' /* beneficio pre pre universitario  */
]

const requiredDate = [
  'GIFTCARD-DELIVERY-NEWBORN-BENEFIT' /* beneficio recien nacido */,
  'DELIVERY-AND-RECEIPT-CERTIFICATE-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */
]

const requiredGiftActivate = [
  'GIFTCARD-RECEPTION-NEWBORN-BENEFIT' /* beneficio recien nacido */
]

const requiredCheckProcess = [
  'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'ALL-INVOICES-AND-TRADES-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */
]

const requiredCommission = [
  'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */
]

const requiredTransferredAmount = [
  'TRANSFERRED-AMOUNT-SCHOLARSHIPS-APPLICANT-OFFICER' /* becas aspirante a oficial (OK) */,
  'TRANSFERRED-AMOUNT-SCHOLARSHIPS-APPLICANT-OFFICER-DAC' /* becas aspirante a oficial (OK) */,
  'TRANSFERRED-AMOUNT-TECHNICAL-PROFESSIONAL' /* Becas Técnico-Profesional (OK) */,
  'TRANSFERRED-AMOUNT-TECHNICAL-PROFESSIONAL-NEWS-SEMESTERS' /* Becas Técnico-Profesional (OK) */,
  'PAYMENT-MADE-UNIVERSITY' /* beca universitaria (OK)  */,
  'TRANSFERRED-AMOUNT-PRE-UNIVERSITY' /* beneficio pre universitario (OK) */,
  'PAYMENT-ONE-MADE' /* beneficio apoyo en caso de fallecimiento de socio (OK) */,
  'PAYMENT-TWO-MADE' /* beneficio apoyo en caso de fallecimiento de socio (OK) */,
  'AID-DEPOSIT' /* beneficio apoyo en Catástrofe (OK) */,
  'PAYMENT-MADE-WORKSHOPS-AND-TRAINING' /* Talleres y capacitaciones (OK) */,
  'GIFTCARD-RECEPTION-NEWBORN-BENEFIT' /* beneficio recien nacido (OK) */,
  'USED-AMOUNT-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'TRANSFERRED-AMOUNT-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  // 'PRESIDENSY-DETERMINATION-DISASTER-SUPPORT' /* beneficio apoyo en Catástrofe  */,
  // 'PRESIDENSY-DETERMINATION-FIRE-SUPPORT' /* beneficio apoyo en INCENDIO  */
  // 'USED-AMOUNT-DISASTER-SUPPORT' /* beneficio apoyo en Catástrofe  */,
  // 'USED-AMOUNT-FIRE-SUPPORT' /* beneficio INCENDIO */,
  'PAYMENT-ONE-MADE-DEATH-OF-MEMBER' /* Fallecimiento */,
  'PAYMENT-TWO-MADE-DEATH-OF-MEMBER' /* Fallecimiento */
]

const requiredUsedAmount = [
  'PAYMENT-MADE-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'AID-DEPOSIT-DISASTER-SUPPORT' /* beneficio apoyo en Catástrofe  */,
  'AID-DEPOSIT-FIRE-SUPPORT' /* beneficio INCENDIO */
]

const requiredPayment = [
  'VERIFICATION-REQUEST-PAYMENT-ONE-DEATH-OF-MEMBER' /* beneficio apoyo en caso de fallecimiento de socio  */,
  'VERIFICATION-REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER' /* beneficio apoyo en caso de fallecimiento de socio  */,
  'VERIFICATION-PURCHASE-ORDER-REQUEST' /* beneficio pre universitario  */,
  'VERIFICATION-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER' /* beneficio pre universitario  */,
  'VERIFICATION-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC' /* beneficio pre universitario  */,
  'VERIFICATION-PURCHASE-ORDER-REQUEST-TECHNICAL-PROFESSIONAL' /* beneficio tecnico  */,
  'VERIFICATION-PURCHASE-ORDER-REQUEST-NEW-SEMESTERS' /* beneficio tecnico  */,
  'VERIFICATION-PAYMENT-MADE-TECHNICAL-PROFESSIONAL-NEW-SEMESTERS' /* Becas Técnico-Profesional   */,
  'VERIFICATION-PURCHASE-ORDER-REQUEST-PRE-UNIVERSITY' /* beneficio pre universitario  */,
  'VERIFICATION-OFFICE-FOR-FINANCES-PRE-UNIVERSITY' /* beneficio pre universitario  */,
  'VERIFICATION-PURCHASE-ORDER-REQUEST-UNIVERSITY' /* beneficio beca universitaria  */,
  'VERIFICATION-DELIVERY-OFFICE-UNIVERSITY' /* beca universitaria  */,
  'VERIFICATION-PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud  */,
  'VERIFICATION-PURCHASE-ORDER-EDIT-REQUEST-SOLIDARITY-HEALTH-FUND',
  'VERIFICATION-PURCHASE-ORDER-REQUEST-TWO-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud  */,
  'VERIFICATION-DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud  */,
  'VERIFICATION-DELIVERY-OFFICE-MULTI-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud  */
]

const requiredPurchaseOrderVerification = [
  'SIGN-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud  */
]

const requiredVadiationOffice = [
  'SIGN-PURCHASE-ORDER-UNIVERSITY' /* beca universitaria  */,
  'SIGN-PURCHASE-ORDER-PRE-UNIVERSITY' /* beneficio pre universitario  */
]

const requiredAuthorization = [
  'PURCHASE-ORDER-AUTORIZATION' /* beneficio pre universitario  */,
  'PURCHASE-ORDER-AUTORIZATION-PRE-UNIVERSITY' /* beneficio pre universitario  */,
  'PURCHASE-ORDER-AUTORIZATION-UNIVERSITY' /* beneficio beca universitaria  */,
  'PURCHASE-ORDER-AUTORIZATION-TECHNICAL-PROFESSIONAL' /* Becas Técnico-Profesional   */,
  'PURCHASE-ORDER-AUTORIZATION-TECHNICAL-PROFESSIONAL-NEW-SEMESTERS' /* Becas Técnico-Profesional   */,
  'PURCHASE-ORDER-AUTORIZATION-APPLICANT-OFFICER' /* Becas Aspirante a Oficial   */,
  'PURCHASE-ORDER-AUTORIZATION-APPLICANT-OFFICER-DAC' /* Becas Aspirante a Oficial   */,
  'PURCHASE-ORDER-AUTORIZATION-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud  */
]

const requiredProposedState = [
  'LEGAL-REPORT-DISASTER-SUPPORT' /* beneficio apoyo en Catástrofe */,
  'LEGAL-REPORT-FIRE-SUPPORT' /* beneficio apoyo en INCENDIO */,
  'MANAGEMENT-DETERMINATION-DISASTER-SUPPORT' /* beneficio apoyo en Catástrofe   */,
  'MANAGEMENT-DETERMINATION-FIRE-SUPPORT' /* beneficio apoyo en incendio  */,
  'PRESIDENSY-DETERMINATION-DISASTER-SUPPORT' /* beneficio apoyo en Catástrofe   */,
  'PRESIDENSY-DETERMINATION-FIRE-SUPPORT' /* beneficio apoyo en incendio  */,
  'DETERMINATION-EXTENSION-HOSPITAL-ACCOMMODATION' /* hospedaje hospitalario */,
  'EVALUATION-CONTROL-SOCIAL-MANAGEMENT-NEWBORN-BENEFIT' /* recien nacido */,
  'STOCK-RECIEVED-NEWBORN-BENEFIT' /* recien nacido */,
  'EVALUATION-COMMISSION-SOLIDARITY-HEALTH-FUND' /* FSS */,
  'AUTHORIZE-REQUEST-PAYMENT-ONE-DEATH-OF-MEMBER',
  'AUTHORIZE-REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER'
]

const requiredAmount = [
  'MANAGEMENT-DETERMINATION-DISASTER-SUPPORT' /* beneficio apoyo en Catástrofe  */,
  'MANAGEMENT-DETERMINATION-FIRE-SUPPORT' /* beneficio apoyo en INCENDIO  */
]

const requiredChangeState = [
  'MANAGEMENT-DETERMINATION-WORKSHOPS-AND-TRAINING' /* Telleres y capacitaciones  */,
  'EVALUATION-COMMISSION-PRE-UNIVERSITY' /* beneficio PRE universitario */,
  /* 'EVALUATION-COMMISSION-SOLIDARITY-HEALTH-FUND', */
  'EVALUATION-COMMISSION-UNIVERSITY' /* beneficio universitario */,
  'EVALUATION-COMMISSION-APPLICANT-OFFICER' /* beneficio universitario */,
  'EVALUATION-COMMISSION-APPLICANT-OFFICER-DAC' /* beneficio universitario */,
  'EVALUATION-COMMISSION-TECHNICAL-PROFESSIONAL' /* beneficiotecnico */,
  'EVALUATION-COMMISSION-NEW-SEMESTERS' /* beneficio tecnico profesional */,
  'EVALUATION-CONTROL-SOCIAL-MANAGEMENT' /*  */,
  'EVALUATION-CONTROL-SOCIAL-MANAGEMENT-DEATH-OF-MEMBER' /*  */,
  'RESIGNATION-OR-LOSS-AUTHORIZATION-RESIGNATION' /* resignation or loss */ /* recien nacido */
  /* 'EVALUATION-CONTROL-SOCIAL-MANAGEMENT-NEWBORN-BENEFIT' */
]

const requiredOfficeStatus = [
  'VALIDATE-AID-APPROVAL-DISASTER-SUPPORT' /* beneficio apoyo en Catástrofe  */,
  'VALIDATE-AID-APPROVAL-FIRE-SUPPORT' /* beneficio apoyo en INCENDIO  */
]

const requiredVoucher = [
  'AID-DEPOSIT-DISASTER-SUPPORT' /* beneficio apoyo en Catástrofe  */,
  'AID-DEPOSIT-FIRE-SUPPORT' /* beneficio INCENDIO  */
]
const requiredInstructive = [
  // 'AID-DEPOSIT-DISASTER-SUPPORT' /* beneficio apoyo en Catástrofe  */,
  // 'AID-DEPOSIT-FIRE-SUPPORT' /* beneficio INCENDIO */
]

const requiredAmountCancel = [
  'AMOUNT-TO-CANCEL-TECHNICAL-PROFESSIONAL' /* Becas Técnico-Profesional   */,
  'AMOUNT-TO-CANCEL-NEW-SEMESTERS' /* beneficio Técnico-Profesional */
]
const requiredPurchaseDetail = []

const requiredEntryDate = [
  'ADD-STAY-CERTIFICATE-HOSPITAL-ACCOMMODATION' /* hospedaje hospitalario */
]

const requiredBackupFeeAmount = [
  'PURCHASE-ORDER-REQUEST-UNIVERSITY' /* beneficio Beca universitaria  */,
  'PURCHASE-ORDER-REQUEST-UNIVERSITY-EDIT-UNIVERSITY' /* beneficio Beca universitaria  */,
  'CORRECT-REQUEST-UNIVERSITY' /* beneficio beca universitaria  */
]
const requiredScholarshipCommission = [
  'PURCHASE-ORDER-REQUEST-UNIVERSITY' /* beneficio Beca universitaria  */,
  'PURCHASE-ORDER-REQUEST-UNIVERSITY-EDIT-UNIVERSITY' /* beneficio Beca universitaria  */,
  'CORRECT-REQUEST-UNIVERSITY' /* beneficio beca universitaria  */
]
const requiredBankData = [
  'PURCHASE-ORDER-REQUEST-UNIVERSITY' /* beneficio Beca universitaria  */,
  'PURCHASE-ORDER-REQUEST-UNIVERSITY-EDIT-UNIVERSITY' /* beneficio Beca universitaria  */,
  'CORRECT-REQUEST-UNIVERSITY' /* beneficio beca universitaria  */
]

const requiredOffice = [
  'PURCHASE-ORDER-REQUEST-UNIVERSITY' /* beneficio Beca universitaria  */,
  'PURCHASE-ORDER-REQUEST-UNIVERSITY-EDIT-UNIVERSITY' /* beneficio Beca universitaria  */,
  'CORRECT-REQUEST-UNIVERSITY' /* beneficio beca universitaria  */,
  // 'GENERATE-PURCHASE-ORDER-UNIVERSITY' /* beneficio beca universitaria  */,
  // 'GENERATE-PURCHASE-ORDER-UNIVERSITY-EDIT-UNIVERSITY' /* beneficio beca universitaria  */,
  // 'GENERATE-PURCHASE-ORDER-PRE-UNIVERSITY' /* beneficio pre universitario  */,
  // 'GENERATE-PURCHASE-ORDER-PRE-UNIVERSITY-EDIT' /* beneficio pre universitario  */,
  // 'SIGN-PURCHASE-ORDER-UNIVERSITY' /* beneficio beca universitaria  */,
  // 'SIGN-PURCHASE-ORDER-PRE-UNIVERSITY' /* beneficio pre universitario  */,
  // 'DELIVERY-OFFICE-UNIVERSITY' /* beneficio beca universitaria  */,
  // 'DELIVERY-OFFICE-UNIVERSITY-EDIT-UNIVERSITY' /* beneficio beca universitaria  */,
  'OFFICE-AND-INVOICE-TECHNICAL-PROFESSIONAL' /* Becas Técnico-Profesional   */,
  'OFFICE-AND-INVOICE-EDIT-TECHNICAL-PROFESSIONAL' /* Becas Técnico-Profesional   */,
  'OFFICE-AND-INVOICE-NEW-SEMESTERS' /* beneficio Técnico-Profesional */,
  'OFFICE-AND-INVOICE-NEW-SEMESTERS-EDIT' /* beneficio Técnico-Profesional */,
  'PURCHASE-ORDER-REQUEST' /* beneficio pre pre universitario  */,
  'PURCHASE-ORDER-REQUEST-EDIT' /* beneficio pre preuniversitario  */,
  'PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER' /* beneficio pre pre universitario  */,
  'PURCHASE-ORDER-REQUEST-EDIT-APPLICANT-OFFICER' /* beneficio pre preuniversitario  */,
  'PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC' /* beneficio pre pre universitario  */,
  'PURCHASE-ORDER-REQUEST-EDIT-APPLICANT-OFFICER-DAC' /* beneficio pre preuniversitario  */,
  'PURCHASE-ORDER-REQUEST-PRE-UNIVERSITY' /* beneficio pre pre universitario  */,
  'PURCHASE-ORDER-REQUEST-EDIT-PRE-UNIVERSITY' /* beneficio pre preuniversitario  */,
  'RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER' /* beneficio aspirante  */,
  'RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER-DAC' /* beneficio aspirante  */
]

const requiredInvoice = [
  'DELIVERY-OFFICE-UNIVERSITY' /* beneficio beca universitaria  */,
  'DELIVERY-OFFICE-UNIVERSITY-EDIT-UNIVERSITY' /* beneficio beca universitaria  */,
  'OFFICE-AND-INVOICE-TECHNICAL-PROFESSIONAL' /* Becas Técnico-Profesional   */,
  'OFFICE-AND-INVOICE-EDIT-TECHNICAL-PROFESSIONAL' /* Becas Técnico-Profesional   */,
  'OFFICE-AND-INVOICE-NEW-SEMESTERS' /* beneficio Técnico-Profesional */,
  'OFFICE-AND-INVOICE-NEW-SEMESTERS-EDIT' /* beneficio Técnico-Profesional */,
  'PURCHASE-ORDER-REQUEST' /* beneficio pre pre universitario */,
  'PURCHASE-ORDER-REQUEST-EDIT' /* beneficio pre preuniversitario */,
  'PURCHASE-ORDER-REQUEST-EDIT-APPLICANT-OFFICER' /* beneficio pre preuniversitario */,
  'PURCHASE-ORDER-REQUEST-EDIT-APPLICANT-OFFICER-DAC' /* beneficio pre preuniversitario */,
  'PURCHASE-ORDER-REQUEST-PRE-UNIVERSITY' /* beneficio pre pre universitario */,
  'PURCHASE-ORDER-REQUEST-EDIT-PRE-UNIVERSITY' /* beneficio pre preuniversitario */,
  'INFORM-THE-PAYMENT-MADE-TWO-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'INFORM-THE-PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'INFORM-THE-PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */
  // 'VERIFICATION-DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */
]

const requiredTariffDifferenceDocuments = [
  'VERIFICATION-DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */
]

const requiredActa = [
  'OFFICE-AND-INVOICE-TECHNICAL-PROFESSIONAL' /* Becas Técnico-Profesional   */,
  'PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER' /* Becas aspirante  */,
  'PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC' /* Becas aspirante  */
]

const requiredPaymentMonitors = [
  'REQUEST-FOR-PAYMENT-TO-MONITORS-WORKSHOPS-AND-TRAINING'
]

const requiredVerificationOfDocuments = [
  'VERIFICATION-PAYMENT-MADE-WORKSHOPS-AND-TRAINING'
  // 'VERIFICATION-DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND'
]

const requiredMoreDescriptions = [
  'THE-UNIVERSITY-WAS-INFORMED-UNIVERSITY',
  'INFORM-THE-UNIVERSITY-PAYMENT-MADE-UNIVERSITY'
]

const requiredReportedEmail = [
  'INFORM-THE-INSTITUTE-PAYMENT-MADE-TECHNICAL-PROFESSIONAL' /* Becas Técnico-Profesional  */,
  'INFORM-THE-INSTITUTE-PAYMENT-MADE-NEW-SEMESTERS' /* Becas Técnico-Profesional  */
]

const requiredReason = ['RESIGNATION-OR-LOSS-BENEFIT-RESIGNATION']

const requiredEeceptionDate = ['RESIGNATION-OR-LOSS-BENEFIT-RESIGNATION']

const requiredBoxPurchase = [
  // 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  // 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'ADD-DEBT-CERTIFICATE-TWO-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'ADD-DEBT-CERTIFICATE-TWO-EDIT-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */
]

const requiredBoxPurchaseTwo = [
  // 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  // 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'ADD-DEBT-CERTIFICATE-TWO-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'ADD-DEBT-CERTIFICATE-TWO-EDIT-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */
]

const requiredPurchaseRequestLetter = [
  'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'ADD-DEBT-CERTIFICATE-TWO-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'ADD-DEBT-CERTIFICATE-TWO-EDIT-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */
]

const requiredQuotesReceived = [
  // 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  // 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'ADD-DOCUMENTATION-FOR-PAYMENTS-AND-OR-PURCHASES-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */
]

const requiredDebtCertificate = [
  // 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  // 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'ADD-DOCUMENTATION-FOR-PAYMENTS-AND-OR-PURCHASES-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */
]

const requiredPurchaseRequestLetterSigned = [
  'VERIFICATION-PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */,
  'VERIFICATION-PURCHASE-ORDER-EDIT-REQUEST-SOLIDARITY-HEALTH-FUND',
  'VERIFICATION-PURCHASE-ORDER-REQUEST-TWO-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */
]

const requiredDateSubmissionReceipts = []

const requiredDateSubmissionReceiptsArr = [
  'INFORM-THE-PAYMENT-MADE-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */
]

const requiredCheckVouchersSend = [
  'INFORM-THE-PAYMENT-MADE-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */
]

const requiredDateCertificateRequest = [
  'ADD-DEBT-CERTIFICATE-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */
]

const requiredDateCertificateReception = [
  'ADD-DEBT-CERTIFICATE-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */
]

const requiredDocumentsSentMedicalInstitution = [
  'ADD-DEBT-CERTIFICATE-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */
]

const requiredDateSend = [
  'DELIVERY-AND-RECEIPT-CERTIFICATE-SOLIDARITY-HEALTH-FUND' /* fondo solidario de salud */
]

const requiredQuestion = [
  'QUESTION-ADD-NEW-SEMESTERS-TECHNICAL-PROFESSIONAL' /* Becas Técnico-Profesional  */
]

export default {
  requiredReason,
  requiredEeceptionDate,
  requiredDescriptions,
  requiredDocuments,
  requiredDate,
  requiredGiftActivate,
  requiredCheckProcess,
  requiredCommission,
  requiredPayment,
  requiredVadiationOffice,
  requiredAuthorization,
  requiredProposedState,
  requiredAmount,
  requiredOfficeStatus,
  requiredVoucher,
  requiredInstructive,
  requiredAmountCancel,
  requiredPurchaseDetail,
  requiredEntryDate,
  requiredBackupFeeAmount,
  requiredScholarshipCommission,
  requiredBankData,
  requiredOffice,
  requiredInvoice,
  requiredTariffDifferenceDocuments,
  requiredTransferredAmount,
  requiredUsedAmount,
  requiredPaymentMonitors,
  requiredVerificationOfDocuments,
  requiredChangeState,
  requiredMoreDescriptions,
  requiredReportedEmail,
  canChangeState,
  canChangeKeyhistory,
  requiredActa,
  canImcompleteStep,
  canDesistProgram,
  requiredBoxPurchase,
  requiredBoxPurchaseTwo,
  requiredPurchaseRequestLetter,
  requiredPurchaseRequestLetterSigned,
  requiredQuotesReceived,
  requiredDebtCertificate,
  requiredDateSubmissionReceipts,
  requiredDateSubmissionReceiptsArr,
  requiredCheckVouchersSend,
  requiredDateCertificateRequest,
  requiredDateCertificateReception,
  requiredDocumentsSentMedicalInstitution,
  requiredDateSend,
  requiredQuestion,
  requiredPurchaseOrderVerification
}
